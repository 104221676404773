import i18n from '@/plugins/i18n'

export default {
  methods: {
    async stationKpis (dt, cases, Frame1, Frame2, idElement, caseSelected) {
      // op1 = 0 for actual values; op1 = 256 for pu values
      const op1 = 0
      // ----
      const KPISCToverloadRate = []
      const KPISCToverutiRate = []
      const KPISCTcapFac = []
      const KPISCTenergySupplied = []
      const KPISDGenergySupplied = []
      const KPISCTenergyLosses = []
      const KPISCTavgPF = []
      const KPISCTavgVun = []
      const KPISCTVunbRate = []
      const KPISCTVqualFactor = []
      const KPISCTVoverRate = []
      const KPISCTVunderRate = []
      const KPISCTVmin = []
      const KPISCTVmax = []
      const KPISCTVavg = []
      const KPISCTSavg = []
      const KPISCTSavgUnb = []

      // ----- Duration Subscription Violation
      const KPISDurationPoutmax = []
      const KPISDurationPinmax = []
      const KPISDurationQoutmax = []
      const KPISDurationQinmax = []

      // ----- Power Subscription Violation
      const KPISPowerPoutmax = []
      const KPISPowerPinmax = []
      const KPISPowerQoutmax = []
      const KPISPowerQinmax = []

      // ----- Table: Object Array
      const dataForKPItable = []

      function KPIsCToverloadRate (Index) {
        KPISCToverloadRate[Index] = {}
        KPISCToverloadRate[Index].KPI = i18n.t('kpi.overload_rate')
        KPISCToverloadRate[Index].unit = '%'
        KPISCToverloadRate[Index].caseName = cases[Index].name
        KPISCToverloadRate[Index].phase = {}
        // For the three phase --------------------------------------------------------
        const vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISCToverloadRate[Index].phase.threePhase = {}
        KPISCToverloadRate[Index].phase.threePhase.value = dt.Results.GetCtOverLoad(cases[Index].idx, Frame1,
          Frame2, trafoIndex, refSide, vioArray)[0]
        KPISCToverloadRate[Index].phase.threePhase.vioInstants = vioArray
        KPISCToverloadRate[Index].phase.threePhase.vioCount = dt.Results.GetCtOverLoad(cases[Index].idx, Frame1,
          Frame2, trafoIndex, refSide, vioArray)[1]
      }
      function KPIsCToverutiRate (Index) {
        KPISCToverutiRate[Index] = {}
        KPISCToverutiRate[Index].KPI = i18n.t('kpi.over_utilization_rate')
        KPISCToverutiRate[Index].unit = '%'
        KPISCToverutiRate[Index].caseName = cases[Index].name
        KPISCToverutiRate[Index].phase = {}
        // For the three phase --------------------------------------------------------
        const vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISCToverutiRate[Index].phase.threePhase = {}
        KPISCToverutiRate[Index].phase.threePhase.value = dt.Results.GetCtOverUti(cases[Index].idx, Frame1,
          Frame2, trafoIndex, refSide, vioArray)[0]
        KPISCToverutiRate[Index].phase.threePhase.vioInstants = vioArray
        KPISCToverutiRate[Index].phase.threePhase.vioCount = dt.Results.GetCtOverUti(cases[Index].idx, Frame1,
          Frame2, trafoIndex, refSide, vioArray)[1]
      }
      function KPIsCTcapFac (Index) {
        KPISCTcapFac[Index] = {}
        KPISCTcapFac[Index].KPI = i18n.t('kpi.capacity_factor')
        KPISCTcapFac[Index].unit = '%'
        KPISCTcapFac[Index].caseName = cases[Index].name
        KPISCTcapFac[Index].phase = {}
        // For the three phase --------------------------------------------------------
        KPISCTcapFac[Index].phase.threePhase = {}
        KPISCTcapFac[Index].phase.threePhase.value = dt.Results.GetCtCapFac(cases[Index].idx, Frame1,
          Frame2, trafoIndex, refSide)
        KPISCTcapFac[Index].phase.threePhase.vioInstants = null
        KPISCTcapFac[Index].phase.threePhase.vioCount = null
      }
      function KPIsCTenergySupplied (Index) {
        KPISCTenergySupplied[Index] = {}
        KPISCTenergySupplied[Index].KPI = i18n.t('kpi.energy_supplied_station')
        KPISCTenergySupplied[Index].unit = 'MWh'
        KPISCTenergySupplied[Index].caseName = cases[Index].name
        KPISCTenergySupplied[Index].phase = {}
        // For the three phase --------------------------------------------------------
        KPISCTenergySupplied[Index].phase.threePhase = {}
        KPISCTenergySupplied[Index].phase.threePhase.value = dt.Results.GetCtEnergySup(cases[Index].idx, Frame1,
          Frame2, trafoIndex, refSide)
        KPISCTenergySupplied[Index].phase.threePhase.value = KPISCTenergySupplied[Index].phase.threePhase.value * -1
        KPISCTenergySupplied[Index].phase.threePhase.vioInstants = null
        KPISCTenergySupplied[Index].phase.threePhase.vioCount = null
      }
      function KPIsDGenergySupplied (Index) {
        KPISDGenergySupplied[Index] = {}
        KPISDGenergySupplied[Index].KPI = i18n.t('kpi.energy_supplied_pv')
        KPISDGenergySupplied[Index].unit = 'MWh'
        KPISDGenergySupplied[Index].caseName = cases[Index].name
        KPISDGenergySupplied[Index].phase = {}
        // For the three phase --------------------------------------------------------
        KPISDGenergySupplied[Index].phase.threePhase = {}
        KPISDGenergySupplied[Index].phase.threePhase.value = dt.Results.GetDgEnergySup(cases[Index].idx, Frame1,
          Frame2, trafoIndex, refSide) / 1000 // it is coming in kWh
        KPISDGenergySupplied[Index].phase.threePhase.vioInstants = null
        KPISDGenergySupplied[Index].phase.threePhase.vioCount = null
      }
      function KPIsCTenergyLosses (Index) {
        KPISCTenergyLosses[Index] = {}
        KPISCTenergyLosses[Index].KPI = i18n.t('kpi.energy_losses')
        KPISCTenergyLosses[Index].unit = 'kWh'
        KPISCTenergyLosses[Index].caseName = cases[Index].name
        KPISCTenergyLosses[Index].phase = {}
        // For the three phase --------------------------------------------------------
        KPISCTenergyLosses[Index].phase.threePhase = {}
        KPISCTenergyLosses[Index].phase.threePhase.value = dt.Results
          .GetCtEnergyLoss(cases[Index].idx, Frame1, Frame2, trafoIndex, refSide)[0]
        KPISCTenergyLosses[Index].phase.threePhase.percentage = dt.Results
          .GetCtEnergyLoss(cases[Index].idx, Frame1, Frame2, trafoIndex, refSide)[1]
        KPISCTenergyLosses[Index].phase.threePhase.vioCount = null
        KPISCTenergyLosses[Index].phase.threePhase.vioInstants = null
        // --For phase A ---------------------------------------------------------
        KPISCTenergyLosses[Index].phase.A = {}
        KPISCTenergyLosses[Index].phase.A.value = dt.Results
          .GetCtPhaseEnergyLoss(cases[Index].idx, Frame1, Frame2, trafoIndex, 0, refSide)[0]
        KPISCTenergyLosses[Index].phase.A.percentage = dt.Results
          .GetCtPhaseEnergyLoss(cases[Index].idx, Frame1, Frame2, trafoIndex, 0, refSide)[1]
        KPISCTenergyLosses[Index].phase.A.vioCount = null
        KPISCTenergyLosses[Index].phase.A.vioInstants = null
        // --For phase B---------------------------------------------------------------------
        KPISCTenergyLosses[Index].phase.B = {}
        KPISCTenergyLosses[Index].phase.B.value = dt.Results
          .GetCtPhaseEnergyLoss(cases[Index].idx, Frame1, Frame2, trafoIndex, 1, refSide)[0]
        KPISCTenergyLosses[Index].phase.B.percentage = dt.Results
          .GetCtPhaseEnergyLoss(cases[Index].idx, Frame1, Frame2, trafoIndex, 1, refSide)[1]
        KPISCTenergyLosses[Index].phase.B.vioCount = null
        KPISCTenergyLosses[Index].phase.B.vioInstants = null
        // ---For phase C-----------------------------------------------------------------------
        KPISCTenergyLosses[Index].phase.C = {}
        KPISCTenergyLosses[Index].phase.C.value = dt.Results
          .GetCtPhaseEnergyLoss(cases[Index].idx, Frame1, Frame2, trafoIndex, 2, refSide)[0]
        KPISCTenergyLosses[Index].phase.C.percentage = dt.Results
          .GetCtPhaseEnergyLoss(cases[Index].idx, Frame1, Frame2, trafoIndex, 2, refSide)[1]
        KPISCTenergyLosses[Index].phase.C.vioCount = null
        KPISCTenergyLosses[Index].phase.C.vioInstants = null
      }
      function KPIsCTavgPF (Index) {
        KPISCTavgPF[Index] = {}
        KPISCTavgPF[Index].KPI = i18n.t('kpi.power_factor_avg')
        KPISCTavgPF[Index].unit = ''
        KPISCTavgPF[Index].caseName = cases[Index].name
        KPISCTavgPF[Index].phase = {}
        // For the three phase --------------------------------------------------------
        KPISCTavgPF[Index].phase.threePhase = {}
        KPISCTavgPF[Index].phase.threePhase.value = dt.Results.GetCtMeanPF(cases[Index].idx, Frame1,
          Frame2, trafoIndex, refSide)
        KPISCTavgPF[Index].phase.threePhase.vioInstants = null
        KPISCTavgPF[Index].phase.threePhase.vioCount = null
      }
      function KPIsCTavgVun (Index) {
        KPISCTavgVun[Index] = {}
        KPISCTavgVun[Index].KPI = i18n.t('kpi.voltage_unbalance_avg')
        KPISCTavgVun[Index].unit = '%'
        KPISCTavgVun[Index].caseName = cases[Index].name
        KPISCTavgVun[Index].phase = {}
        // For the three phase --------------------------------------------------------
        KPISCTavgVun[Index].phase.threePhase = {}
        KPISCTavgVun[Index].phase.threePhase.value = dt.Results.GetMeanCtVu(cases[Index].idx,
          Frame1, Frame2, trafoIndex, refSide)
        KPISCTavgVun[Index].phase.threePhase.vioInstants = null
        KPISCTavgVun[Index].phase.threePhase.vioCount = null
      }
      function KPIsCTVunbRate (Index) {
        KPISCTVunbRate[Index] = {}
        KPISCTVunbRate[Index].KPI = i18n.t('kpi.voltage_unbalance_rate')
        KPISCTVunbRate[Index].unit = '%'
        KPISCTVunbRate[Index].caseName = cases[Index].name
        KPISCTVunbRate[Index].phase = {}
        // For the three phase --------------------------------------------------------
        const vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISCTVunbRate[Index].phase.threePhase = {}
        KPISCTVunbRate[Index].phase.threePhase.value = dt.Results.GetRateCtVu(cases[Index].idx,
          Frame1, Frame2, trafoIndex, refSide, vioArray)[0]
        KPISCTVunbRate[Index].phase.threePhase.vioInstants = vioArray
        KPISCTVunbRate[Index].phase.threePhase.vioCount = dt.Results.GetRateCtVu(cases[Index].idx,
          Frame1, Frame2, trafoIndex, refSide, vioArray)[1]
      }
      function KPIsCTVqualFactor (Index, option1) {
        const op2 = 2 // for average between 3 phases
        const op = option1 + op2
        KPISCTVqualFactor[Index] = {}
        KPISCTVqualFactor[Index].KPI = i18n.t('kpi.voltage_qualified_rate')
        KPISCTVqualFactor[Index].unit = '%'
        KPISCTVqualFactor[Index].caseName = cases[Index].name
        KPISCTVqualFactor[Index].phase = {}
        // For the three phase --------------------------------------------------------
        KPISCTVqualFactor[Index].phase.threePhase = {}
        KPISCTVqualFactor[Index].phase.threePhase.value = dt.Results.GetCtQualV(cases[Index].idx,
          Frame1, Frame2, trafoIndex, refSide, op)
        KPISCTVqualFactor[Index].phase.threePhase.vioCount = null
        KPISCTVqualFactor[Index].phase.threePhase.vioInstants = null
        // --For phase A ---------------------------------------------------------
        KPISCTVqualFactor[Index].phase.A = {}
        KPISCTVqualFactor[Index].phase.A.value = dt.Results.GetCtPhaseQualV(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 0, refSide)
        KPISCTVqualFactor[Index].phase.A.vioCount = null
        KPISCTVqualFactor[Index].phase.A.vioInstants = null
        // --For phase B---------------------------------------------------------------------
        KPISCTVqualFactor[Index].phase.B = {}
        KPISCTVqualFactor[Index].phase.B.value = dt.Results.GetCtPhaseQualV(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 1, refSide)
        KPISCTVqualFactor[Index].phase.B.vioCount = null
        KPISCTVqualFactor[Index].phase.B.vioInstants = null
        // ---For phase C-----------------------------------------------------------------------
        KPISCTVqualFactor[Index].phase.C = {}
        KPISCTVqualFactor[Index].phase.C.value = dt.Results.GetCtPhaseQualV(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 2, refSide)
        KPISCTVqualFactor[Index].phase.C.vioCount = null
        KPISCTVqualFactor[Index].phase.C.vioInstants = null
      }
      function KPIsCTVoverRate (Index, option1) {
        const op2 = 2 // for average between 3 phases
        const op = option1 + op2
        KPISCTVoverRate[Index] = {}
        KPISCTVoverRate[Index].KPI = i18n.t('kpi.voltage_overvoltage')
        KPISCTVoverRate[Index].unit = '%'
        KPISCTVoverRate[Index].caseName = cases[Index].name
        KPISCTVoverRate[Index].phase = {}
        // For the three phase --------------------------------------------------------
        let vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISCTVoverRate[Index].phase.threePhase = {}
        KPISCTVoverRate[Index].phase.threePhase.value = dt.Results.GetCtOverV(cases[Index].idx,
          Frame1, Frame2, trafoIndex, refSide, op, vioArray)[0]
        KPISCTVoverRate[Index].phase.threePhase.vioInstants = vioArray
        KPISCTVoverRate[Index].phase.threePhase.vioCount = dt.Results.GetCtOverV(cases[Index].idx,
          Frame1, Frame2, trafoIndex, refSide, op, vioArray)[1]
        // --For phase A ---------------------------------------------------------
        vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISCTVoverRate[Index].phase.A = {}
        KPISCTVoverRate[Index].phase.A.value = dt.Results.GetCtPhaseOverV(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 0, refSide, vioArray)[0]
        KPISCTVoverRate[Index].phase.A.vioInstants = vioArray
        KPISCTVoverRate[Index].phase.A.vioCount = dt.Results.GetCtPhaseOverV(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 0, refSide, vioArray)[1]
        // --For phase B---------------------------------------------------------------------
        vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISCTVoverRate[Index].phase.B = {}
        KPISCTVoverRate[Index].phase.B.value = dt.Results.GetCtPhaseOverV(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 1, refSide, vioArray)[0]
        KPISCTVoverRate[Index].phase.B.vioInstants = vioArray
        KPISCTVoverRate[Index].phase.B.vioCount = dt.Results.GetCtPhaseOverV(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 1, refSide, vioArray)[1]
        // ---For phase C-----------------------------------------------------------------------
        vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISCTVoverRate[Index].phase.C = {}
        KPISCTVoverRate[Index].phase.C.value = dt.Results.GetCtPhaseOverV(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 2, refSide, vioArray)[0]
        KPISCTVoverRate[Index].phase.C.vioInstants = vioArray
        KPISCTVoverRate[Index].phase.C.vioCount = dt.Results.GetCtPhaseOverV(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 2, refSide, vioArray)[1]
      }
      function KPIsCTVunderRate (Index, option1) {
        const op2 = 2 // for average between 3 phases
        const op = option1 + op2
        KPISCTVunderRate[Index] = {}
        KPISCTVunderRate[Index].KPI = i18n.t('kpi.voltage_undervoltage')
        KPISCTVunderRate[Index].unit = '%'
        KPISCTVunderRate[Index].caseName = cases[Index].name
        KPISCTVunderRate[Index].phase = {}
        // For the three phase --------------------------------------------------------
        let vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISCTVunderRate[Index].phase.threePhase = {}
        KPISCTVunderRate[Index].phase.threePhase.value = dt.Results.GetCtUnderV(cases[Index].idx,
          Frame1, Frame2, trafoIndex, refSide, op, vioArray)[0]
        KPISCTVunderRate[Index].phase.threePhase.vioInstants = vioArray
        KPISCTVunderRate[Index].phase.threePhase.vioCount = dt.Results.GetCtUnderV(cases[Index].idx,
          Frame1, Frame2, trafoIndex, refSide, op, vioArray)[1]
        // --For phase A ---------------------------------------------------------
        vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISCTVunderRate[Index].phase.A = {}
        KPISCTVunderRate[Index].phase.A.value = dt.Results.GetCtPhaseUnderV(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 0, refSide, vioArray)[0]
        KPISCTVunderRate[Index].phase.A.vioInstants = vioArray
        KPISCTVunderRate[Index].phase.A.vioCount = dt.Results.GetCtPhaseUnderV(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 0, refSide, vioArray)[1]
        // --For phase B---------------------------------------------------------------------
        vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISCTVunderRate[Index].phase.B = {}
        KPISCTVunderRate[Index].phase.B.value = dt.Results.GetCtPhaseUnderV(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 1, refSide, vioArray)[0]
        KPISCTVunderRate[Index].phase.B.vioInstants = vioArray
        KPISCTVunderRate[Index].phase.B.vioCount = dt.Results.GetCtPhaseUnderV(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 1, refSide, vioArray)[1]
        // ---For phase C-----------------------------------------------------------------------
        vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISCTVunderRate[Index].phase.C = {}
        KPISCTVunderRate[Index].phase.C.value = dt.Results.GetCtPhaseUnderV(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 2, refSide, vioArray)[0]
        KPISCTVunderRate[Index].phase.C.vioInstants = vioArray
        KPISCTVunderRate[Index].phase.C.vioCount = dt.Results.GetCtPhaseUnderV(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 2, refSide, vioArray)[1]
      }
      function KPIsCTVmin (Index, option1) {
        const op2 = 0// for minimum between 3 phases
        const op = option1 + op2
        KPISCTVmin[Index] = {}
        KPISCTVmin[Index].KPI = i18n.t('kpi.voltage_min')
        KPISCTVmin[Index].unit = 'V'
        KPISCTVmin[Index].caseName = cases[Index].name
        KPISCTVmin[Index].phase = {}
        // For the three  phase --------------------------------------------------------
        KPISCTVmin[Index].phase.threePhase = {}
        KPISCTVmin[Index].phase.threePhase.value = dt.Results.GetCtVmin(cases[Index].idx,
          Frame1, Frame2, trafoIndex, refSide, op)
        KPISCTVmin[Index].phase.threePhase.vioCount = null
        KPISCTVmin[Index].phase.threePhase.vioInstants = null
        // --For phase A ---------------------------------------------------------
        KPISCTVmin[Index].phase.A = {}
        KPISCTVmin[Index].phase.A.value = dt.Results.GetCtPhaseVmin(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 0, refSide, op)
        KPISCTVmin[Index].phase.A.vioCount = null
        KPISCTVmin[Index].phase.A.vioInstants = null
        // --For phase B---------------------------------------------------------------------
        KPISCTVmin[Index].phase.B = {}
        KPISCTVmin[Index].phase.B.value = dt.Results.GetCtPhaseVmin(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 1, refSide, op)
        KPISCTVmin[Index].phase.B.vioCount = null
        KPISCTVmin[Index].phase.B.vioInstants = null
        // ---For phase C-----------------------------------------------------------------------
        KPISCTVmin[Index].phase.C = {}
        KPISCTVmin[Index].phase.C.value = dt.Results.GetCtPhaseVmin(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 2, refSide, op)
        KPISCTVmin[Index].phase.C.vioCount = null
        KPISCTVmin[Index].phase.C.vioInstants = null
        // ---For the neutral ------------------------------------------------------------------
        KPISCTVmin[Index].phase.N = {}
        KPISCTVmin[Index].phase.N.value = dt.Results.GetCtPhaseVmin(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 3, refSide, op)
        KPISCTVmin[Index].phase.N.vioCount = null
        KPISCTVmin[Index].phase.N.vioInstants = null
      }
      function KPIsCTVmax (Index, option1) {
        const op2 = 1 // for max between 3 phases
        const op = option1 + op2
        KPISCTVmax[Index] = {}
        KPISCTVmax[Index].KPI = i18n.t('kpi.voltage_max')
        KPISCTVmax[Index].unit = 'V'
        KPISCTVmax[Index].caseName = cases[Index].name
        KPISCTVmax[Index].phase = {}
        // For the three  phase --------------------------------------------------------
        KPISCTVmax[Index].phase.threePhase = {}
        KPISCTVmax[Index].phase.threePhase.value = dt.Results.GetCtVmax(cases[Index].idx,
          Frame1, Frame2, trafoIndex, refSide, op)
        KPISCTVmax[Index].phase.threePhase.vioCount = null
        KPISCTVmax[Index].phase.threePhase.vioInstants = null
        // --For phase A ---------------------------------------------------------
        KPISCTVmax[Index].phase.A = {}
        KPISCTVmax[Index].phase.A.value = dt.Results.GetCtPhaseVmax(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 0, refSide, op)
        KPISCTVmax[Index].phase.A.vioCount = null
        KPISCTVmax[Index].phase.A.vioInstants = null
        // --For phase B---------------------------------------------------------------------
        KPISCTVmax[Index].phase.B = {}
        KPISCTVmax[Index].phase.B.value = dt.Results.GetCtPhaseVmax(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 1, refSide, op)
        KPISCTVmax[Index].phase.B.vioCount = null
        KPISCTVmax[Index].phase.B.vioInstants = null
        // ---For phase C-----------------------------------------------------------------------
        KPISCTVmax[Index].phase.C = {}
        KPISCTVmax[Index].phase.C.value = dt.Results.GetCtPhaseVmax(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 2, refSide, op)
        KPISCTVmax[Index].phase.C.vioCount = null
        KPISCTVmax[Index].phase.C.vioInstants = null
        // ---For the neutral ------------------------------------------------------------------
        KPISCTVmax[Index].phase.N = {}
        KPISCTVmax[Index].phase.N.value = dt.Results.GetCtPhaseVmax(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 3, refSide, op)
        KPISCTVmax[Index].phase.N.vioCount = null
        KPISCTVmax[Index].phase.N.vioInstants = null
      }
      function KPIsCTVavg (Index, option1) {
        const op2 = 2 // for avg between 3 phases
        const op = option1 + op2
        KPISCTVavg[Index] = {}
        KPISCTVavg[Index].KPI = i18n.t('kpi.voltage_avg')
        KPISCTVavg[Index].unit = 'V'
        KPISCTVavg[Index].caseName = cases[Index].name
        KPISCTVavg[Index].phase = {}
        // For the three  phase --------------------------------------------------------
        KPISCTVavg[Index].phase.threePhase = {}
        KPISCTVavg[Index].phase.threePhase.value = dt.Results.GetCtVavg(cases[Index].idx,
          Frame1, Frame2, trafoIndex, refSide, op)
        KPISCTVavg[Index].phase.threePhase.vioCount = null
        KPISCTVavg[Index].phase.threePhase.vioInstants = null
        // --For phase A ---------------------------------------------------------
        KPISCTVavg[Index].phase.A = {}
        KPISCTVavg[Index].phase.A.value = dt.Results.GetCtPhaseVavg(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 0, refSide, op)
        KPISCTVavg[Index].phase.A.vioCount = null
        KPISCTVavg[Index].phase.A.vioInstants = null
        // --For phase B---------------------------------------------------------------------
        KPISCTVavg[Index].phase.B = {}
        KPISCTVavg[Index].phase.B.value = dt.Results.GetCtPhaseVavg(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 1, refSide, op)
        KPISCTVavg[Index].phase.B.vioCount = null
        KPISCTVavg[Index].phase.B.vioInstants = null
        // ---For phase C-----------------------------------------------------------------------
        KPISCTVavg[Index].phase.C = {}
        KPISCTVavg[Index].phase.C.value = dt.Results.GetCtPhaseVavg(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 2, refSide, op)
        KPISCTVavg[Index].phase.C.vioCount = null
        KPISCTVavg[Index].phase.C.vioInstants = null
        // ---For the neutral ------------------------------------------------------------------
        KPISCTVavg[Index].phase.N = {}
        KPISCTVavg[Index].phase.N.value = dt.Results.GetCtPhaseVavg(cases[Index].idx,
          Frame1, Frame2, trafoIndex, 3, refSide, op)
        KPISCTVavg[Index].phase.N.vioCount = null
        KPISCTVavg[Index].phase.N.vioInstants = null
      }
      function KPIsCTSavg (Index) {
        KPISCTSavg[Index] = {}
        KPISCTSavg[Index].KPI = i18n.t('kpi.apparent_power_avg')
        KPISCTSavg[Index].unit = 'kVA'
        KPISCTSavg[Index].caseName = cases[Index].name
        KPISCTSavg[Index].phase = {}
        // For the three phase --------------------------------------------------------
        KPISCTSavg[Index].phase.threePhase = {}
        KPISCTSavg[Index].phase.threePhase.value = dt.Results.GetCtSavg(cases[Index].idx, Frame1,
          Frame2, trafoIndex, refSide)
        KPISCTSavg[Index].phase.threePhase.vioCount = null
        KPISCTSavg[Index].phase.threePhase.vioInstants = null
        // --For phase A ---------------------------------------------------------
        KPISCTSavg[Index].phase.A = {}
        KPISCTSavg[Index].phase.A.value = dt.Results.GetCtPhaseSavg(cases[Index].idx, Frame1,
          Frame2, trafoIndex, 0, refSide)
        KPISCTSavg[Index].phase.A.vioCount = null
        KPISCTSavg[Index].phase.A.vioInstants = null
        // --For phase B---------------------------------------------------------------------
        KPISCTSavg[Index].phase.B = {}
        KPISCTSavg[Index].phase.B.value = dt.Results.GetCtPhaseSavg(cases[Index].idx, Frame1,
          Frame2, trafoIndex, 1, refSide)
        KPISCTSavg[Index].phase.B.vioCount = null
        KPISCTSavg[Index].phase.B.vioInstants = null
        // ---For phase C-----------------------------------------------------------------------
        KPISCTSavg[Index].phase.C = {}
        KPISCTSavg[Index].phase.C.value = dt.Results.GetCtPhaseSavg(cases[Index].idx, Frame1,
          Frame2, trafoIndex, 2, refSide)
        KPISCTSavg[Index].phase.C.vioCount = null
        KPISCTSavg[Index].phase.C.vioInstants = null
        // ---For the neutral ------------------------------------------------------------------
        KPISCTSavg[Index].phase.N = {}
        KPISCTSavg[Index].phase.N.value = dt.Results.GetCtPhaseSavg(cases[Index].idx, Frame1,
          Frame2, trafoIndex, 3, refSide)
        KPISCTSavg[Index].phase.N.vioCount = null
        KPISCTSavg[Index].phase.N.vioInstants = null
      }
      function KPIsCTSavgUnb (Index) {
        KPISCTSavgUnb[Index] = {}
        KPISCTSavgUnb[Index].KPI = i18n.t('kpi.apparent_power_phase_share')
        KPISCTSavgUnb[Index].unit = '%'
        KPISCTSavgUnb[Index].caseName = cases[Index].name
        KPISCTSavgUnb[Index].phase = {}
        // --For phase A ---------------------------------------------------------
        KPISCTSavgUnb[Index].phase.A = {}
        KPISCTSavgUnb[Index].phase.A.value = dt.Results.GetCtMeanPhaseUnbal(cases[Index].idx, Frame1,
          Frame2, trafoIndex, 0, refSide)[1]
        KPISCTSavgUnb[Index].phase.A.vioCount = null
        KPISCTSavgUnb[Index].phase.A.vioInstants = null
        // --For phase B---------------------------------------------------------------------
        KPISCTSavgUnb[Index].phase.B = {}
        KPISCTSavgUnb[Index].phase.B.value = dt.Results.GetCtMeanPhaseUnbal(cases[Index].idx, Frame1,
          Frame2, trafoIndex, 1, refSide)[1]
        KPISCTSavgUnb[Index].phase.B.vioCount = null
        KPISCTSavgUnb[Index].phase.B.vioInstants = null
        // ---For phase C-----------------------------------------------------------------------
        KPISCTSavgUnb[Index].phase.C = {}
        KPISCTSavgUnb[Index].phase.C.value = dt.Results.GetCtMeanPhaseUnbal(cases[Index].idx, Frame1,
          Frame2, trafoIndex, 2, refSide)[1]
        KPISCTSavgUnb[Index].phase.C.vioCount = null
        KPISCTSavgUnb[Index].phase.C.vioInstants = null
      }

      // -- Duration Subscription Violations
      function KPIsDurationPoutmax (Index, pout) {
        KPISDurationPoutmax[Index] = {}
        KPISDurationPoutmax[Index].KPI = i18n.t('kpi.duration_p_out_max')
        KPISDurationPoutmax[Index].unit = ''
        KPISDurationPoutmax[Index].caseName = cases[Index].name
        KPISDurationPoutmax[Index].phase = {}
        // 1. Incurrences (Number): The number of times that the subscription P+ limit was overpassed
        const spviour = dt.Results.GetSpVioDur(cases[Index].idx, Frame1, Frame2, trafoIndex, 0, pout)
        KPISDurationPoutmax[Index].phase.threePhase = {}
        KPISDurationPoutmax[Index].phase.threePhase.value = spviour[0]
        KPISDurationPoutmax[Index].phase.threePhase.vioCount = null
        KPISDurationPoutmax[Index].phase.threePhase.vioInstants = null
        KPISDurationPoutmax[Index].phase.threePhase.unit = ''
        // 2. Incurrences (Total Duration): The total number of hours that the P+ power was beyond the subscription P+ limit.
        KPISDurationPoutmax[Index].phase.A = {}
        KPISDurationPoutmax[Index].phase.A.value = spviour[1]
        KPISDurationPoutmax[Index].phase.A.vioCount = null
        KPISDurationPoutmax[Index].phase.A.vioInstants = null
        KPISDurationPoutmax[Index].phase.A.unit = i18n.t('hours')
        // 3. Incurrences (Avg Duration): The average duration (hours) of the P+ incurrences beyond the subscription P+ limit.
        KPISDurationPoutmax[Index].phase.B = {}
        KPISDurationPoutmax[Index].phase.B.value = spviour[2]
        KPISDurationPoutmax[Index].phase.B.vioCount = null
        KPISDurationPoutmax[Index].phase.B.vioInstants = null
        KPISDurationPoutmax[Index].phase.B.unit = i18n.t('hours')
        // 4. Incurrences (STD Duration): The standard deviation of the P+ incurrences duration (hours) beyond the subscription P+ limit
        KPISDurationPoutmax[Index].phase.C = {}
        KPISDurationPoutmax[Index].phase.C.value = spviour[3]
        KPISDurationPoutmax[Index].phase.C.vioCount = null
        KPISDurationPoutmax[Index].phase.C.vioInstants = null
        KPISDurationPoutmax[Index].phase.C.unit = i18n.t('hours')
      }
      function KPIsDurationPinmax (Index, pin) {
        KPISDurationPinmax[Index] = {}
        KPISDurationPinmax[Index].KPI = i18n.t('kpi.duration_p_in_max')
        KPISDurationPinmax[Index].unit = ''
        KPISDurationPinmax[Index].caseName = cases[Index].name
        KPISDurationPinmax[Index].phase = {}
        const spviour = dt.Results.GetSpVioDur(cases[Index].idx, Frame1, Frame2, trafoIndex, 1, pin)
        // 9. Incurrences (Number): The number of times that the subscription P- limit was overpassed
        KPISDurationPinmax[Index].phase.threePhase = {}
        KPISDurationPinmax[Index].phase.threePhase.value = spviour[0]
        KPISDurationPinmax[Index].phase.threePhase.vioCount = null
        KPISDurationPinmax[Index].phase.threePhase.vioInstants = null
        KPISDurationPinmax[Index].phase.threePhase.unit = ''
        // 10. Incurrences (Total Duration): The total number of hours that the P- power was beyond the subscription P- limit.
        KPISDurationPinmax[Index].phase.A = {}
        KPISDurationPinmax[Index].phase.A.value = spviour[1]
        KPISDurationPinmax[Index].phase.A.vioCount = null
        KPISDurationPinmax[Index].phase.A.vioInstants = null
        KPISDurationPinmax[Index].phase.A.unit = i18n.t('hours')
        // 11. Incurrences (Avg Duration): The average duration (hours) of the P- incurrences beyond the subscription P- limit.
        KPISDurationPinmax[Index].phase.B = {}
        KPISDurationPinmax[Index].phase.B.value = spviour[2]
        KPISDurationPinmax[Index].phase.B.vioCount = null
        KPISDurationPinmax[Index].phase.B.vioInstants = null
        KPISDurationPinmax[Index].phase.B.unit = i18n.t('hours')
        // 12. Incurrences (STD Duration): The standard deviation of the P- incurrences duration (hours) beyond the subscription P- limit.
        KPISDurationPinmax[Index].phase.C = {}
        KPISDurationPinmax[Index].phase.C.value = spviour[3]
        KPISDurationPinmax[Index].phase.C.vioCount = null
        KPISDurationPinmax[Index].phase.C.vioInstants = null
        KPISDurationPinmax[Index].phase.C.unit = i18n.t('hours')
      }
      function KPIsDurationQoutmax (Index, qout) {
        KPISDurationQoutmax[Index] = {}
        KPISDurationQoutmax[Index].KPI = i18n.t('kpi.duration_q_out_max')
        KPISDurationQoutmax[Index].unit = ''
        KPISDurationQoutmax[Index].caseName = cases[Index].name
        KPISDurationQoutmax[Index].phase = {}
        const spviour = dt.Results.GetSpVioDur(cases[Index].idx, Frame1, Frame2, trafoIndex, 2, qout)
        // 17. Incurrences (Number): The number of times that the subscription Q+ limit was overpassed
        KPISDurationQoutmax[Index].phase.threePhase = {}
        KPISDurationQoutmax[Index].phase.threePhase.value = spviour[0]
        KPISDurationQoutmax[Index].phase.threePhase.vioCount = null
        KPISDurationQoutmax[Index].phase.threePhase.vioInstants = null
        KPISDurationQoutmax[Index].phase.threePhase.unit = ''
        // 18. Incurrences (Total Duration): The total number of hours that the Q+ power was beyond the subscription Q+ limit.
        KPISDurationQoutmax[Index].phase.A = {}
        KPISDurationQoutmax[Index].phase.A.value = spviour[1]
        KPISDurationQoutmax[Index].phase.A.vioCount = null
        KPISDurationQoutmax[Index].phase.A.vioInstants = null
        KPISDurationQoutmax[Index].phase.A.unit = i18n.t('hours')
        // 19. Incurrences (Avg Duration): The average duration (hours) of the Q+ incurrences beyond the subscription Q+ limit
        KPISDurationQoutmax[Index].phase.B = {}
        KPISDurationQoutmax[Index].phase.B.value = spviour[2]
        KPISDurationQoutmax[Index].phase.B.vioCount = null
        KPISDurationQoutmax[Index].phase.B.vioInstants = null
        KPISDurationQoutmax[Index].phase.B.unit = i18n.t('hours')
        // 20. Incurrences (STD Duration): The standard deviation of the Q+ incurrences duration (hours) beyond the subscription Q+ limit.
        KPISDurationQoutmax[Index].phase.C = {}
        KPISDurationQoutmax[Index].phase.C.value = spviour[3]
        KPISDurationQoutmax[Index].phase.C.vioCount = null
        KPISDurationQoutmax[Index].phase.C.vioInstants = null
        KPISDurationQoutmax[Index].phase.C.unit = i18n.t('hours')
      }
      function KPIsDurationQinmax (Index, qin) {
        KPISDurationQinmax[Index] = {}
        KPISDurationQinmax[Index].KPI = i18n.t('kpi.duration_q_in_max')
        KPISDurationQinmax[Index].unit = ''
        KPISDurationQinmax[Index].caseName = cases[Index].name
        KPISDurationQinmax[Index].phase = {}
        const spviour = dt.Results.GetSpVioDur(cases[Index].idx, Frame1, Frame2, trafoIndex, 3, qin)
        // 24. Incurrences (Number): The number of times that the subscription Q- limit was overpassed
        KPISDurationQinmax[Index].phase.threePhase = {}
        KPISDurationQinmax[Index].phase.threePhase.value = spviour[0]
        KPISDurationQinmax[Index].phase.threePhase.vioCount = null
        KPISDurationQinmax[Index].phase.threePhase.vioInstants = null
        KPISDurationQinmax[Index].phase.threePhase.unit = ''
        // 25. Incurrences (Total Duration): The total number of hours that the Q- power was beyond the subscription Q- limit.
        KPISDurationQinmax[Index].phase.A = {}
        KPISDurationQinmax[Index].phase.A.value = spviour[1]
        KPISDurationQinmax[Index].phase.A.vioCount = null
        KPISDurationQinmax[Index].phase.A.vioInstants = null
        KPISDurationQinmax[Index].phase.A.unit = i18n.t('hours')
        // 26. Incurrences (Avg Duration): The average duration (hours) of the Q- incurrences beyond the subscription Q- limit.
        KPISDurationQinmax[Index].phase.B = {}
        KPISDurationQinmax[Index].phase.B.value = spviour[2]
        KPISDurationQinmax[Index].phase.B.vioCount = null
        KPISDurationQinmax[Index].phase.B.vioInstants = null
        KPISDurationQinmax[Index].phase.B.unit = i18n.t('hours')
        // 27. Incurrences (STD Duration): The standard deviation of the Q- incurrences duration (hours) beyond the subscription Q- limit
        KPISDurationQinmax[Index].phase.C = {}
        KPISDurationQinmax[Index].phase.C.value = spviour[3]
        KPISDurationQinmax[Index].phase.C.vioCount = null
        KPISDurationQinmax[Index].phase.C.vioInstants = null
        KPISDurationQinmax[Index].phase.C.unit = i18n.t('hours')
      }
      // ---- Formula : GetSpVioPower(BufferIndex, Frame1, Frame2, VsIndex, Type, max_value)
      // ---- Type: Type of violation; 0 = P_out, 1 = P_in, 2 = Q_out, 3 = Q_in Max_value: P_OUT_MAX, P_IN_MAX, Q_OUT_MAX, or Q_IN_MAX (KW, or KVAr) (+)
      function KPIsPowerPoutmax (Index, pout) {
        KPISPowerPoutmax[Index] = {}
        KPISPowerPoutmax[Index].KPI = i18n.t('kpi.power_p_out_max')
        KPISPowerPoutmax[Index].unit = ''
        KPISPowerPoutmax[Index].caseName = cases[Index].name
        KPISPowerPoutmax[Index].phase = {}
        const spviopower = dt.Results.GetSpVioPower(cases[Index].idx, Frame1, Frame2, trafoIndex, 0, pout)
        // 5. Power of incurrences (Max): The amount of maximum delta power in kW that the P+ power was beyond the subscription P+ limit
        KPISPowerPoutmax[Index].phase.threePhase = {}
        KPISPowerPoutmax[Index].phase.threePhase.value = spviopower[0]
        KPISPowerPoutmax[Index].phase.threePhase.vioCount = null
        KPISPowerPoutmax[Index].phase.threePhase.vioInstants = null
        KPISPowerPoutmax[Index].phase.threePhase.unit = 'kW'
        // 6. Power of incurrences (Avg): The average delta power in kW of the incurrences beyond the subscription P+ limit
        KPISPowerPoutmax[Index].phase.A = {}
        KPISPowerPoutmax[Index].phase.A.value = spviopower[1]
        KPISPowerPoutmax[Index].phase.A.vioCount = null
        KPISPowerPoutmax[Index].phase.A.vioInstants = null
        KPISPowerPoutmax[Index].phase.A.unit = 'kW'
        // 7. Power of incurrences (STD): The standard deviation in kW of the delta power of incurrences beyond the subscription P+ limit.
        KPISPowerPoutmax[Index].phase.B = {}
        KPISPowerPoutmax[Index].phase.B.value = spviopower[2]
        KPISPowerPoutmax[Index].phase.B.vioCount = null
        KPISPowerPoutmax[Index].phase.B.vioInstants = null
        KPISPowerPoutmax[Index].phase.B.unit = 'kW'
        // 8. Energy of incurrences: The amount of energy (MWh) that was demanded with P+ power beyond the subscription P+ limit.
        KPISPowerPoutmax[Index].phase.C = {}
        KPISPowerPoutmax[Index].phase.C.value = spviopower[3]
        KPISPowerPoutmax[Index].phase.C.vioCount = null
        KPISPowerPoutmax[Index].phase.C.vioInstants = null
        KPISPowerPoutmax[Index].phase.C.unit = 'KWh' // In PFR says this unit instead of MWh
      }
      function KPIsPowerPinmax (Index, pin) {
        KPISPowerPinmax[Index] = {}
        KPISPowerPinmax[Index].KPI = i18n.t('kpi.power_p_in_max')
        KPISPowerPinmax[Index].unit = ''
        KPISPowerPinmax[Index].caseName = cases[Index].name
        KPISPowerPinmax[Index].phase = {}
        const spviopower = dt.Results.GetSpVioPower(cases[Index].idx, Frame1, Frame2, trafoIndex, 1, pin)
        // 13. Power of incurrences (Max): The amount of maximum delta power in kW that the P- power was beyond the subscription P- limit.
        KPISPowerPinmax[Index].phase.threePhase = {}
        KPISPowerPinmax[Index].phase.threePhase.value = spviopower[0]
        KPISPowerPinmax[Index].phase.threePhase.vioCount = null
        KPISPowerPinmax[Index].phase.threePhase.vioInstants = null
        KPISPowerPinmax[Index].phase.threePhase.unit = 'kW'
        // 14. Power of incurrences (Avg): The average delta power in kW of the incurrences beyond the subscription P- limit.
        KPISPowerPinmax[Index].phase.A = {}
        KPISPowerPinmax[Index].phase.A.value = spviopower[1]
        KPISPowerPinmax[Index].phase.A.vioCount = null
        KPISPowerPinmax[Index].phase.A.vioInstants = null
        KPISPowerPinmax[Index].phase.A.unit = 'kW'
        // 15. Power of incurrences (STD): The standard deviation in kW of the delta power of incurrences beyond the subscription P- limit.
        KPISPowerPinmax[Index].phase.B = {}
        KPISPowerPinmax[Index].phase.B.value = spviopower[2]
        KPISPowerPinmax[Index].phase.B.vioCount = null
        KPISPowerPinmax[Index].phase.B.vioInstants = null
        KPISPowerPinmax[Index].phase.B.unit = 'kW'
        // 16. Energy of incurrences: The amount of energy (MWh) that was demanded with P- power beyond the subscription P- limit
        KPISPowerPinmax[Index].phase.C = {}
        KPISPowerPinmax[Index].phase.C.value = spviopower[3]
        KPISPowerPinmax[Index].phase.C.vioCount = null
        KPISPowerPinmax[Index].phase.C.vioInstants = null
        KPISPowerPinmax[Index].phase.C.unit = 'KWh'
      }
      function KPIsPowerQoutmax (Index, qout) {
        KPISPowerQoutmax[Index] = {}
        KPISPowerQoutmax[Index].KPI = i18n.t('kpi.power_q_out_max')
        KPISPowerQoutmax[Index].unit = ''
        KPISPowerQoutmax[Index].caseName = cases[Index].name
        KPISPowerQoutmax[Index].phase = {}
        const spviopower = dt.Results.GetSpVioPower(cases[Index].idx, Frame1, Frame2, trafoIndex, 2, qout)
        // 21. Power of incurrences (Max): The amount of maximum delta power in kVAr that the Q+ power was beyond the subscription Q+ limit.
        KPISPowerQoutmax[Index].phase.threePhase = {}
        KPISPowerQoutmax[Index].phase.threePhase.value = spviopower[0]
        KPISPowerQoutmax[Index].phase.threePhase.vioCount = null
        KPISPowerQoutmax[Index].phase.threePhase.vioInstants = null
        KPISPowerQoutmax[Index].phase.threePhase.unit = 'kW'
        // 22. Power of incurrences (Avg): The average delta power in kVAr of the incurrences beyond the subscription Q+ limit.
        KPISPowerQoutmax[Index].phase.A = {}
        KPISPowerQoutmax[Index].phase.A.value = spviopower[1]
        KPISPowerQoutmax[Index].phase.A.vioCount = null
        KPISPowerQoutmax[Index].phase.A.vioInstants = null
        KPISPowerQoutmax[Index].phase.A.unit = 'kW'
        // 23. Power of incurrences (STD): The standard deviation in kVAr of the delta power of incurrences beyond the subscription Q+ limit
        KPISPowerQoutmax[Index].phase.B = {}
        KPISPowerQoutmax[Index].phase.B.value = spviopower[2]
        KPISPowerQoutmax[Index].phase.B.vioCount = null
        KPISPowerQoutmax[Index].phase.B.vioInstants = null
        KPISPowerQoutmax[Index].phase.B.unit = 'kW'
        // The amount of energy (KWh) that was demanded beyond the subscription limit
        KPISPowerQoutmax[Index].phase.C = {}
        KPISPowerQoutmax[Index].phase.C.value = spviopower[3]
        KPISPowerQoutmax[Index].phase.C.vioCount = null
        KPISPowerQoutmax[Index].phase.C.vioInstants = null
        KPISPowerQoutmax[Index].phase.C.unit = 'KWh'
      }
      function KPIsPowerQinmax (Index, qin) {
        KPISPowerQinmax[Index] = {}
        KPISPowerQinmax[Index].KPI = i18n.t('kpi.power_q_in_max')
        KPISPowerQinmax[Index].unit = ''
        KPISPowerQinmax[Index].caseName = cases[Index].name
        KPISPowerQinmax[Index].phase = {}
        const spviopower = dt.Results.GetSpVioPower(cases[Index].idx, Frame1, Frame2, trafoIndex, 3, qin)
        // 28. Power of incurrences (Max): The amount of maximum delta power in kVAr that the Q- power was beyond the subscription Q- limit.
        KPISPowerQinmax[Index].phase.threePhase = {}
        KPISPowerQinmax[Index].phase.threePhase.value = spviopower[0]
        KPISPowerQinmax[Index].phase.threePhase.vioCount = null
        KPISPowerQinmax[Index].phase.threePhase.vioInstants = null
        KPISPowerQinmax[Index].phase.threePhase.unit = 'kW'
        // 29. Power of incurrences (Avg): The average delta power in kVAr of the incurrences beyond the subscription Q- limit.
        KPISPowerQinmax[Index].phase.A = {}
        KPISPowerQinmax[Index].phase.A.value = spviopower[1]
        KPISPowerQinmax[Index].phase.A.vioCount = null
        KPISPowerQinmax[Index].phase.A.vioInstants = null
        KPISPowerQinmax[Index].phase.A.unit = 'kW'
        // 30. Power of incurrences (STD): The standard deviation in kVAr of the delta power of incurrences beyond the subscription Q- limit.
        KPISPowerQinmax[Index].phase.B = {}
        KPISPowerQinmax[Index].phase.B.value = spviopower[2]
        KPISPowerQinmax[Index].phase.B.vioCount = null
        KPISPowerQinmax[Index].phase.B.vioInstants = null
        KPISPowerQinmax[Index].phase.B.unit = 'kW'
        // The amount of energy (KWh) that was demanded beyond the subscription limit
        KPISPowerQinmax[Index].phase.C = {}
        KPISPowerQinmax[Index].phase.C.value = spviopower[3]
        KPISPowerQinmax[Index].phase.C.vioCount = null
        KPISPowerQinmax[Index].phase.C.vioInstants = null
        KPISPowerQinmax[Index].phase.C.unit = 'KWh'
      }

      function orderDataForTable () {
        let temp3P = {}; let tempA = {}; let tempB = {}; let tempC = {}; let tempN = {}
        let flag3P = false; let flagA = false; let flagB = false
        let flagC = false; let flagN = false

        const computes = [
          { objectName: 'KPISCToverloadRate', objectElem: KPISCToverloadRate, phaseOne: '3P', phaseTwo: 'A', phaseThree: 'B', phaseFour: 'C', phaseFive: 'N', base: 100 },
          { objectName: 'KPISCToverutiRate', objectElem: KPISCToverutiRate, phaseOne: '3P', phaseTwo: 'A', phaseThree: 'B', phaseFour: 'C', phaseFive: 'N', base: 100 },
          { objectName: 'KPISCTcapFac', objectElem: KPISCTcapFac, phaseOne: '3P', phaseTwo: 'A', phaseThree: 'B', phaseFour: 'C', phaseFive: 'N', base: 100 },
          { objectName: 'KPISCTenergySupplied', objectElem: KPISCTenergySupplied, phaseOne: '3P', phaseTwo: 'A', phaseThree: 'B', phaseFour: 'C', phaseFive: 'N', base: 100 },
          { objectName: 'KPISDGenergySupplied', objectElem: KPISDGenergySupplied, phaseOne: '3P', phaseTwo: 'A', phaseThree: 'B', phaseFour: 'C', phaseFive: 'N', base: 100 },
          { objectName: 'KPISCTavgPF', objectElem: KPISCTavgPF, phaseOne: '3P', phaseTwo: 'A', phaseThree: 'B', phaseFour: 'C', phaseFive: 'N', base: 100 },
          { objectName: 'KPISCTavgVun', objectElem: KPISCTavgVun, phaseOne: '3P', phaseTwo: 'A', phaseThree: 'B', phaseFour: 'C', phaseFive: 'N', base: 100 },
          { objectName: 'KPISCTVunbRate', objectElem: KPISCTVunbRate, phaseOne: '3P', phaseTwo: 'A', phaseThree: 'B', phaseFour: 'C', phaseFive: 'N', base: 100 },
          { objectName: 'KPISCTVqualFactor', objectElem: KPISCTVqualFactor, phaseOne: '3P', phaseTwo: 'A', phaseThree: 'B', phaseFour: 'C', phaseFive: 'N', base: 100 },
          { objectName: 'KPISCTVoverRate', objectElem: KPISCTVoverRate, phaseOne: '3P', phaseTwo: 'A', phaseThree: 'B', phaseFour: 'C', phaseFive: 'N', base: 100 },
          { objectName: 'KPISCTVunderRate', objectElem: KPISCTVunderRate, phaseOne: '3P', phaseTwo: 'A', phaseThree: 'B', phaseFour: 'C', phaseFive: 'N', base: 100 },
          { objectName: 'KPISCTVmin', objectElem: KPISCTVmin, phaseOne: '3P', phaseTwo: 'A', phaseThree: 'B', phaseFour: 'C', phaseFive: 'N', base: 100 },
          { objectName: 'KPISCTVmax', objectElem: KPISCTVmax, phaseOne: '3P', phaseTwo: 'A', phaseThree: 'B', phaseFour: 'C', phaseFive: 'N', base: 100 },
          { objectName: 'KPISCTVavg', objectElem: KPISCTVavg, phaseOne: '3P', phaseTwo: 'A', phaseThree: 'B', phaseFour: 'C', phaseFive: 'N', base: 100 },
          { objectName: 'KPISCTSavg', objectElem: KPISCTSavg, phaseOne: '3P', phaseTwo: 'A', phaseThree: 'B', phaseFour: 'C', phaseFive: 'N', base: 100 },
          { objectName: 'KPISCTSavgUnb', objectElem: KPISCTSavgUnb, phaseOne: '3P', phaseTwo: 'A', phaseThree: 'B', phaseFour: 'C', phaseFive: 'N', base: 10 },
          { objectName: 'KPISCTenergyLosses', objectElem: KPISCTenergyLosses, phaseOne: '3P', phaseTwo: 'A', phaseThree: 'B', phaseFour: 'C', phaseFive: 'N', base: 10 },
          { objectName: 'KPISDurationPoutmax', objectElem: KPISDurationPoutmax, phaseOne: 'Instants', phaseTwo: 'Total Duration', phaseThree: 'Ave Duration', phaseFour: 'Std Dev', base: 100 },
          { objectName: 'KPISDurationPinmax', objectElem: KPISDurationPinmax, phaseOne: 'Instants', phaseTwo: 'Total Duration', phaseThree: 'Ave Duration', phaseFour: 'Std Dev', base: 100 },
          { objectName: 'KPISDurationQoutmax', objectElem: KPISDurationQoutmax, phaseOne: 'Instants', phaseTwo: 'Total Duration', phaseThree: 'Ave Duration', phaseFour: 'Std Dev', base: 100 },
          { objectName: 'KPISDurationQinmax', objectElem: KPISDurationQinmax, phaseOne: 'Instants', phaseTwo: 'Total Duration', phaseThree: 'Ave Duration', phaseFour: 'Std Dev', base: 100 },
          { objectName: 'KPISPowerPoutmax', objectElem: KPISPowerPoutmax, phaseOne: 'Max Delta', phaseTwo: 'Ave Delta', phaseThree: 'Std Dev', phaseFour: 'Energy Beyond Subs', base: 100 },
          { objectName: 'KPISPowerPinmax', objectElem: KPISPowerPinmax, phaseOne: 'Max Delta', phaseTwo: 'Ave Delta', phaseThree: 'Std Dev', phaseFour: 'Energy Beyond Subs', base: 100 },
          { objectName: 'KPISPowerQoutmax', objectElem: KPISPowerQoutmax, phaseOne: 'Max Delta', phaseTwo: 'Ave Delta', phaseThree: 'Std Dev', phaseFour: 'Energy Beyond Subs', base: 100 },
          { objectName: 'KPISPowerQinmax', objectElem: KPISPowerQinmax, phaseOne: 'Max Delta', phaseTwo: 'Ave Delta', phaseThree: 'Std Dev', phaseFour: 'Energy Beyond Subs', base: 100 }

        ]

        for (let index = 0; index < computes.length; index++) {
          temp3P = {}; tempA = {}; tempB = {}; tempC = {}; tempN = {}
          flag3P = false; flagA = false; flagB = false; flagC = false; flagN = false

          const originalIndex = index

          computes[index].objectElem.forEach((element, index) => {
            if (index === 0) {
              if (element.phase.threePhase) {
                temp3P.KPI = element.KPI
                temp3P.Unit = element.phase.threePhase.unit ?? element.unit
                temp3P.Phase = computes[originalIndex].phaseOne
                flag3P = true
                temp3P.base = element.phase.threePhase.value
                temp3P.base = Math.round(temp3P.base * computes[originalIndex].base) / computes[originalIndex].base

                if (computes[originalIndex].objectName === 'KPISCTenergyLosses') {
                  temp3P.base = `${temp3P.base}
                  (${Math.round(element.phase.threePhase.percentage * 100) / 100}%)`
                }

                if (cases.length === 1) {
                  dataForKPItable.push(temp3P)
                }
              }
              if (element.phase.A) {
                tempA.KPI = element.KPI
                tempA.Unit = element.phase.A.unit ?? element.unit
                tempA.Phase = computes[originalIndex].phaseTwo
                flagA = true
                tempA.base = element.phase.A.value
                tempA.base = Math.round(tempA.base * computes[originalIndex].base) / computes[originalIndex].base
                if (computes[originalIndex].objectName === 'KPISCTenergyLosses') {
                  tempA.base = `${tempA.base}
                (${Math.round(element.phase.A.percentage * 100) / 100} %)`
                }
                if (cases.length === 1) {
                  dataForKPItable.push(tempA)
                }
              }
              if (element.phase.B) {
                tempB.KPI = element.KPI
                tempB.Unit = element.phase.B.unit ?? element.unit
                tempB.Phase = computes[originalIndex].phaseThree
                flagB = true
                tempB.base = element.phase.B.value
                tempB.base = Math.round(tempB.base * computes[originalIndex].base) / computes[originalIndex].base
                if (computes[originalIndex].objectName === 'KPISCTenergyLosses') {
                  tempB.base = `${tempB.base}
                (${Math.round(element.phase.B.percentage * 100) / 100} %)`
                }
                if (cases.length === 1) {
                  dataForKPItable.push(tempB)
                }
              }
              if (element.phase.C) {
                tempC.KPI = element.KPI
                tempC.Unit = element.phase.C.unit ?? element.unit
                tempC.Phase = computes[originalIndex].phaseFour
                flagC = true
                tempC.base = element.phase.C.value
                tempC.base = Math.round(tempC.base * computes[originalIndex].base) / computes[originalIndex].base
                if (computes[originalIndex].objectName === 'KPISCTenergyLosses') {
                  tempC.base = `${tempC.base}
                (${Math.round(element.phase.C.percentage * 100) / 100} %)`
                }
                if (cases.length === 1) {
                  dataForKPItable.push(tempC)
                }
              }
              if (element.phase.N) {
                tempN.KPI = element.KPI
                tempN.Unit = element.phase.N.unit ?? element.unit
                tempN.Phase = computes[originalIndex].phaseFive
                flagN = true
                tempN.base = element.phase.N.value
                tempN.base = Math.round(tempN.base * computes[originalIndex].base) / computes[originalIndex].base
                if (computes[originalIndex].objectName === 'KPISCTenergyLosses') {
                  temp3P[element.caseName] = `${temp3P[element.caseName]}
                (${Math.round(element.phase.threePhase.percentage * 100) / 100}%)`
                }
                if (cases.length === 1) {
                  dataForKPItable.push(tempN)
                }
              }
            } else if (index !== 0) {
              if (flag3P === true) {
                temp3P[element.caseName] = element.phase.threePhase.value
                temp3P[element.caseName] = Math.round(temp3P[element.caseName] * computes[originalIndex].base) / computes[originalIndex].base
                if (computes[originalIndex].objectName === 'KPISCTenergyLosses') {
                  temp3P[element.caseName] = `${temp3P[element.caseName]}
                (${Math.round(element.phase.threePhase.percentage * 100) / 100}%)`
                }
                if (index === cases.length - 1) {
                  dataForKPItable.push(temp3P)
                }
              }
              if (flagA === true) {
                tempA[element.caseName] = element.phase.A.value
                tempA[element.caseName] = Math.round(tempA[element.caseName] * computes[originalIndex].base) / computes[originalIndex].base
                if (computes[originalIndex].objectName === 'KPISCTenergyLosses') {
                  tempA[element.caseName] = `${tempA[element.caseName]}
                (${Math.round(element.phase.A.percentage * 100) / 100}%)`
                }
                if (index === cases.length - 1) {
                  dataForKPItable.push(tempA)
                }
              }
              if (flagB === true) {
                tempB[element.caseName] = element.phase.B.value
                tempB[element.caseName] = Math.round(tempB[element.caseName] * computes[originalIndex].base) / computes[originalIndex].base
                if (computes[originalIndex].objectName === 'KPISCTenergyLosses') {
                  tempB[element.caseName] = `${tempB[element.caseName]}
                (${Math.round(element.phase.B.percentage * 100) / 100}%)`
                }
                if (index === cases.length - 1) {
                  dataForKPItable.push(tempB)
                }
              }
              if (flagC === true) {
                tempC[element.caseName] = element.phase.C.value
                tempC[element.caseName] = Math.round(tempC[element.caseName] * computes[originalIndex].base) / computes[originalIndex].base
                if (computes[originalIndex].objectName === 'KPISCTenergyLosses') {
                  tempC[element.caseName] = `${tempC[element.caseName]}
                (${Math.round(element.phase.C.percentage * 100) / 100}%)`
                }
                if (index === cases.length - 1) {
                  dataForKPItable.push(tempC)
                }
              }
              if (flagN === true) {
                tempN[element.caseName] = element.phase.N.value
                tempN[element.caseName] = Math.round(tempN[element.caseName] * computes[originalIndex].base) / computes[originalIndex].base
                if (index === cases.length - 1) {
                  dataForKPItable.push(tempN)
                }
              }
            }
          })
        }
      }

      if (!this.idTransformer) {
        // This will be the default transformer ID (if Any)
        this.idTransformer = dt.DB.exec(`SELECT ID from Transformer2 where Station = ${idElement}`)[0].values[0]
      }
      const trafoIndex = dt.Results.GetCtIndex(Number(this.idTransformer))

      // W1 -> refSide 0 , W2 -> refSide 1, W3 -> refSide 2 (Not sure)
      const refSide = this.windings.find(w => w.ID === this.idWinding).index
      const WindingIndex = dt.Results.CT_winding_first[trafoIndex] + refSide

      const busStation = dt.Results.CT_bus[WindingIndex]
      const hasSuscriptionLimit = dt.Results.is_VS_Bus(busStation)
      const stationLimits = this.overloadAnalysis.find(station => station.idStation === idElement)

      for (let iter = 0; iter < cases.length; iter += 1) {
        dt.Results.AdjustBusCount(dt.cases[cases[iter].name].mod.NewBusCount) // Calculate well kpis, plots with ESS
        // Initialize overall KPI function
        KPIsCToverloadRate(iter)
        KPIsCToverutiRate(iter)
        KPIsCTcapFac(iter)
        KPIsCTenergySupplied(iter)
        KPIsDGenergySupplied(iter)
        KPIsCTenergyLosses(iter)
        KPIsCTavgPF(iter) //
        KPIsCTavgVun(iter)
        KPIsCTVunbRate(iter)
        KPIsCTVqualFactor(iter)
        KPIsCTVoverRate(iter, op1)
        KPIsCTVunderRate(iter, op1)
        KPIsCTVmin(iter, op1)
        KPIsCTVmax(iter, op1)
        KPIsCTVavg(iter, op1)
        KPIsCTSavg(iter) // 1
        KPIsCTSavgUnb(iter)

        if (hasSuscriptionLimit === 1) {
          // Duration - getVsIndex
          KPIsDurationPoutmax(iter, stationLimits.consumptionLimitP) // P+
          KPIsDurationPinmax(iter, stationLimits.injectionLimitP) // P-
          KPIsDurationQoutmax(iter, stationLimits.consumptionLimitQ) // Q+
          KPIsDurationQinmax(iter, stationLimits.injectionLimitQ) // Q-

          // Power - getVsIndex
          KPIsPowerPoutmax(iter, stationLimits.consumptionLimitP) // P+
          KPIsPowerPinmax(iter, stationLimits.injectionLimitP) // P-
          KPIsPowerQoutmax(iter, stationLimits.consumptionLimitQ) // Q+
          KPIsPowerQinmax(iter, stationLimits.injectionLimitQ) // Q-
        }
      }
      orderDataForTable()

      dt.Results.AdjustBusCount(dt.cases[caseSelected].mod.NewBusCount) // Calculate well kpis, plots with ESS
      return dataForKPItable
    }
  }
}
